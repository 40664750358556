import VueCookies from 'vue-cookies'
import sha1 from "sha1"
const addClickLinkCount = (that, path, pageName, postName, id) => {
    if (VueCookies.get(path) == sha1(path)) {
        console.log("no set cookie");
    } else {
        console.log("set cookie");
        VueCookies.set(path, sha1(path), '30m')
        setClickLinkConutRequest(that, pageName, postName, id)
    }
}

const setClickLinkConutRequest = (that, pageName, postName, id) => {
    const api = `${process.env.VUE_APP_API_URL}/${pageName}/clicks`;
    let postParams = {};
    postParams[postName] = id;
    let postConfig = {
        headers: {
            "Content-Type": "application/json"
        },
    }
    that.axios.post(api, postParams, postConfig).then((response) => {
        console.log("conunt:" +response['data']);
    })
}

export {
    addClickLinkCount,
}